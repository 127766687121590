import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useParams  } from "react-router-dom";
import "../styles/ProductSelection.scss";
import ColorPalette from "./ColorPalette";
import { addToCart } from '../features/cartSlice';
import { CartItem, Product } from "../common/types";
import { Color } from "../models/Color";
import { productData, fetchProductByItemAsync, fetchProductByNameAsync, selectProductItemLoading, setBasePrice, colorState, currProductType, resetColors, basePrice, resetState, updateColorList } from '../features/productSlice';
import { tugStickImages, xStickImages, doubleHaloImages, snuffleMat2Images, snuffleBallImages, snuffleMatImages, haloImages, pomImages, daddyLongLegImages, tugAPillarImages, serpentImages } from "../common/imageMap";
import MuiDropdownButtonWrapper from "./buttons/MuiDropDownButtonWrapper";
import TabComponent from "./TabComponent";
import MuiButton from "./buttons/MuiButton";
import { fetchColorsAsync } from '../features/colorSlice';
import { detailsMap } from '../common/textContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Carousel, Tabs,TabsHeader,TabsBody,Tab,TabPanel, } from "@material-tailwind/react";

interface ProductSelectionPage {
    productType: string;
    fromTypeSelection: boolean;
}

const ProductSelectionPage: React.FC<ProductSelectionPage> = ({ productType, fromTypeSelection }) => {
    const { productItem } = useParams<{ productItem: string }>();
    console.log("this is product item...", productItem)
    // const [productImages, setProductImages] = useState<string[]>([])
    const productTypes = useSelector((state: RootState) => state.products.productType)
    const size = useSelector((state: RootState) => state.products.size)
    const level = useSelector((state: RootState) => state.products.level)
    const length = useSelector((state: RootState) => state.products.length)

    const dispatch = useDispatch<AppDispatch>();
    const productLoaded = useSelector(productData)
    const isLoading = useSelector(selectProductItemLoading)
    const colorStateFromSelector = useSelector(colorState)
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [selectedSize, setSelectedSize] = useState<string>("mega");
    const [selectedLevel, setSelectedLevel] = useState<string>("regular");
    const [selectedLength, setSelectedLength] = useState<number>(0)
    const [textareaValue, setTextareaValue] = useState("");
    const [expandColors, setExpandColors] = useState(false);
    const [productList, setProductList] = useState<Product[]>([])
    const [currentPrice, setCurrentPrice] = useState(0)
    const baseSelectedPrice = useSelector(basePrice)
    
    const [paletteList, setPaletteList] = useState([])

    // length for snuffle snake
    const [lengthValue, setLengthValue] = useState(0);
    const prevLengthValue = useRef(lengthValue);

    const increaseLength = () => {
        setLengthValue((prev) => prev + 15);
    }
    const decreaseLength = () => {
        setLengthValue((prev) => Math.max(prev - 15, 0));
    }

    const productImageMap: Record<string, string[]> = {
      "Snuffle Bol": snuffleBallImages,
      "Snuffle Mat": snuffleMatImages,
      "Snuffle Mat 2.0": snuffleMat2Images,
      "Tug Stik": tugStickImages,
      "X-Stik": xStickImages,
      "Double Halo": doubleHaloImages,
      "Halo": haloImages,
      "Daddy Long Legs": daddyLongLegImages,
      "Tug-a-pillar": tugAPillarImages,
      "Snuffle Serpent": serpentImages,
      default: pomImages,
    };
  
    const productImages =  productImageMap[productItem || "default"] || productImageMap.default;;

    useEffect(() => {
        setExpandColors(false)
        const fetchColor = async () => {
            try {
                const data = await dispatch(fetchColorsAsync())
                
                setPaletteList(data.payload)
            } catch (error) {
                console.error("Error fetching colors: ", error)
            }
        }

        fetchColor()
    }, [])

    useEffect(() => {
        const fetchProduct = async () => {
            console.log("fetching product...")
            try {
                if(productItem) {
                    const data = await dispatch(fetchProductByItemAsync({ productItem: productItem }));
                }
            } catch (error) {
                console.error("Error fetching product: ", error)
            }
        }

        dispatch(resetState())
        fetchProduct();

    }, [productType])


    useEffect(() => {
        if (productLoaded.length) {
          console.log("product loaded...")
            setCurrentPrice(productLoaded[0].price)
            setProductList(productLoaded)
            setSelectedOption(productLoaded[0].name)
            setSelectedSize(productLoaded[0].size)
            setSelectedLength(productLoaded[0].length)
            setSelectedLevel(productLoaded[0].level)
            setCurrentPrice(productLoaded[0].price)
            dispatch(setBasePrice(productLoaded[0].price))
        }
    }, [productLoaded])

    useEffect(() => {
      // console.log("this is base selected price...", baseSelectedPrice)
      // let calculatedPrice = baseSelectedPrice;

      // if (selectedSize) calculatedPrice += 10; // Example increment for size
      // if (selectedLevel) calculatedPrice += 15; // Example increment for level
      // if (selectedLength) calculatedPrice += 5; // Example increment for length
      // calculatedPrice += colorStateFromSelector.length * 2; // Price per selected color
  
      // setCurrentPrice(calculatedPrice);
        if (!isLoading && productList.length > 0) {
          console.log("in here?")
            let productFound = productList.find((product) => {
                return product.size === selectedSize && product.level === selectedLevel;
            });
            if (productFound) {
              console.log("Product found, base price:", productFound.price);
              setCurrentPrice(productFound.price); // Update the base price
              dispatch(setBasePrice(productFound.price));
              dispatch(resetColors());
          }
        }
    }, [isLoading, selectedLevel, selectedSize])

    useEffect(() => {
        let newPrice = baseSelectedPrice;
      //   if (lengthValue !== prevLengthValue.current) {
      //     const lengthAdjustment = Math.floor((lengthValue - 15) / 15) * 10;
  
      //     console.log("Length adjustment:", lengthAdjustment);
      //     newPrice += lengthAdjustment; // Adjust based on length
      //     prevLengthValue.current = lengthValue; // Update previous length value
      // }
      if (lengthValue > 0) {
        const lengthAdjustment = Math.floor(lengthValue/ 15) * 10;
  
          console.log("Length adjustment:", lengthAdjustment);
          newPrice += lengthAdjustment;
      }

      const additionalColors = Math.max(colorStateFromSelector.length - 4, 0);
      console.log("additional colors...", additionalColors)
        newPrice += additionalColors * 5;
        console.log("new price...", newPrice)

      console.log("New calculated price:", newPrice);
    setCurrentPrice(newPrice);
    }, [lengthValue, colorStateFromSelector])

    const handleSubmit = (submitType: string) => {
        let productFound = productList.find((product: Product) => 
            product.name === selectedOption && product.size == selectedSize && product.level == selectedLevel
        )
        console.log(productFound)
        if (productFound && submitType == "default") {
            console.log("product found...")
            // if (colorStateFromSelector.length > 4) {
            //     productPrice = productFound.price + ((colorStateFromSelector.length - 4) * 5)
            // } else {
            //     productPrice = productFound.price
            // }
            // if (lengthValue > 0) {
            //     productPrice = productFound.price + (Math.floor((colorStateFromSelector.length - 15) / 15) * 10);
            // } else {
            //     productPrice = productFound.price;
            // }
            let addProductToCart = {
                id: productFound.id,
                name: productFound.name,
                item: productFound.item,
                quantity: 1,
                colors: colorStateFromSelector,
                description: productFound.description,
                price: currentPrice,
                size: selectedSize,
                length: lengthValue,
                type: productType,
                level: selectedLevel,
                additionalInfo: textareaValue
            };
            dispatch(addToCart(addProductToCart));
            dispatch(resetColors())
            setExpandColors(false)
        } else if(productFound && submitType == "surprise") {
            let addProductToCart = {
                id: productFound.id,
                name: productFound.name,
                item: productFound.item,
                quantity: 1,
                colors: [""],
                description: productFound.description,
                price: currentPrice,
                size: selectedSize,
                type: productType,
                length: lengthValue,
                level: selectedLevel,
                additionalInfo: "Surprise Me"
            };
            dispatch(addToCart(addProductToCart));
            dispatch(resetColors())
            setExpandColors(false)
        }
        else {
            console.error(`Product with name '${selectedOption}' not found.`);
        }

        setLengthValue(0)
    };


    const handleSize = (option: string) => {
        setSelectedSize(option)
    }

    const handleLevel = (option: string) => {
        setSelectedLevel(option)
    }

    const handleSelect = (option: string) => {
        setSelectedOption(option);
    };

    
    const handleChangeColor = (data: string[]) => {
      console.log("adding color...", data)
    }


    let textWithLineBreaks
    if(fromTypeSelection) {
        textWithLineBreaks = detailsMap[productItem!].split('\n\n').map((line, index) => (
            <React.Fragment key={index}>
                <p className="!mt-4 !text-gray-800" style={{ fontSize: '0.8rem', lineHeight: '22px' }} dangerouslySetInnerHTML={{ __html: line }} />
            </React.Fragment>
        ));
    }
    // } else {
    //     textWithLineBreaks = detailsMap[productType].split('\n\n').map((line, index) => (
    //         <React.Fragment key={index}>
    //             <p className="!mt-4 text-base font-normal leading-[27px] !text-gray-800" dangerouslySetInnerHTML={{__html: line}} />
    //         </React.Fragment>
    //     ));
    // }
    
    if (isLoading) {
        return (
            <div className="container mx-auto flex text-center min-h-40">
                <Button variant="text" loading={true} placeholder=" "className="m-auto">
                    Loading
                </Button>
            </div>
        )
    }
    
return (
    <section className="md:py-16 px-8 h-full">
    <div className="mx-auto container md:flex md:flex-row h-full md:gap-10">

    <Carousel placeholder={undefined} className="rounded-xl md:basis-2/5 md:h-100" 
    navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
        {new Array(length).fill("").map((_, i) => (
            <span
            key={i}
            className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-black" : "w-4 bg-black/50"
            }`}
            onClick={() => setActiveIndex(i)}
            />
        ))}
        </div>
    )}
    >
        {productImages.map((image: string, index: number) => {
            return (
                <div className="md:flex md:justify-center md:h-full p-20"><img src={`/${image}`} key={index} className="max-w-100 self-center	" /></div>
            )
        })}
    </Carousel>

      <div className="md:flex md:basis-3/5 h-100 overflow-y-auto">
        <div className="inline-block flex-none w-full h-full">
        <h3 className="block font-sans text-3xl antialiased font-semibold leading-snug tracking-normal text-inherit md:text-left text-center mt-3 md:mt-0">
        {productItem}{productType}
        </h3>
            {textWithLineBreaks}

            <h6 className="mt-8 block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">
                Style
            </h6>
            <div className="mb-8 flex items-center gap-2 color-palette flex-wrap">
            {productTypes.map((product:string) => (
                <Button variant={(product == selectedOption) ? "filled" : "outlined"} className="me-3" placeholder={undefined} onClick={() => handleSelect(product)} >{product}</Button>
            ))}
            </div>

            {size[0] != null ? (
                <div>
                    <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">Size</h6>
                    <div className="mb-8 flex items-center gap-2 color-palette flex-wrap">
                    {size.map((sz:string) => (
                        <Button variant={(sz == selectedSize) ? "filled" : "outlined"} className="me-3" placeholder={undefined} onClick={() => handleSize(sz)} >{sz}</Button>
                    ))}
                    </div>
                </div>
            ) : ""}

                    
            {length[0] != 0 ? (
                <div>
                    <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">Length (cm)</h6>
                    <div className="mb-8 flex items-center gap-2 color-palette flex-wrap">
                        <Button
                        variant="outlined"
                        className="text-2xl"
                        onClick={decreaseLength}
                        placeholder={undefined}
                        disabled={lengthValue == 0}
                        >
                        -
                        </Button>
                        <span className="text-lg font-semibold">{lengthValue}</span>
                        <Button
                        variant="outlined"
                        className="text-2xl"
                        placeholder={undefined}
                        onClick={increaseLength}
                        disabled={lengthValue == 60}
                        >
                        +
                        </Button>
                    </div>
                </div>
            ) : ""}
            


            {level[0] != null ? (
                <div>
                    <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">Level</h6>
                    <div className="mb-8 flex items-center gap-2 color-palette flex-wrap">
                    {level.map((lvl:string) => (
                        <Button variant={(lvl == selectedLevel) ? "filled" : "outlined"} className="me-3" placeholder={undefined} onClick={() => handleLevel(lvl)} >{lvl}</Button>
                    ))}
                    </div>
                </div>
            ):""}

            <ColorPalette colorList={paletteList} title={"Choose up to 4 complimentary colours"} numOfColors={7} fromSecondary={false} changeColor={handleChangeColor}/>

            <div className="flex w-full items-center gap-3 md:w-1/2 " style={{marginBottom: '3rem'}}>
                <Button disabled={colorStateFromSelector.length <= 0 ? true : false}
                onClick={() => handleSubmit("default")}  color="gray" className="w-61" placeholder={undefined}>
                Add to Cart &mdash; ${(currentPrice).toFixed(2)}
            </Button>
            <Button
                onClick={() => handleSubmit("surprise")}  color="gray" className="w-65" placeholder={undefined}>
                Surprise me &mdash; ${(currentPrice).toFixed(2)}
            </Button>
            </div>

            <div className="!text-gray-600" style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
                <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">Disclaimer</h6>
                <p>Snuffle products & toys should never be used to replace proper exercise or training. All products from The Curious One should be used under supervision.</p>
                <p>No toy is indestructible! If your pet starts chewing on the product, please remove it and review potential reasons such as frustration (you may need to start even slower to teach your pet how to snuffle!).
    Feel free to reach out if you have any questions.</p>
            </div>
        </div>
      </div>
    </div>
  </section>
)

}

export default ProductSelectionPage;
