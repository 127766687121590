export const productImageMap: Record<string, string> = {
    candy: 'assets/img/products/bol/01. Candy (Petite Size, Regular).png',
    puppucino: 'assets/img/products/bol/02. Puppucino (Mid Size, Beginner).png',
    greyscale: 'assets/img/products/bol/03. Greyscale Monochrome (Mid Size, Regular).png',
    paddlepup: 'assets/img/products/bol/04. Paddlepup (Mid Size, Regular).png',
    confetti: 'assets/img/products/mat/Snuffle Mat (Confetti, Surprise Me).png',
    // Add more products here as needed
};

export const productItemImageMap: Record<string, string> = {
    snufflebol: 'assets/img/products/bol/BOL 2.png',
    snufflemat: 'assets/img/products/mat/MAT Rainbow - Stripe.png',
    snufflepompom: 'assets/img/products/pom/POM 2.png',
    tugstik: 'TUG STIK 2.png',
    xstik: 'X STIK.png',
    doublehalo: 'INFINITY RINGS.png',
    halo: 'OCTO REG.png',
    daddylonglegs: 'DADDY LONG LEGS MEGA.png',
    tugapillar: 'TUGAPILLAR MEGA.png',
    snuffleserpent: 'assets/img/products/serpent/serpent_1.PNG',
    snuffle_mat_2_0: 'assets/img/products/mat2.0/Mat 2.0 Stripe Combined.png',
}

export const snuffleBallImages: string[] = [
    'assets/img/products/bol/BOL 10.png',
    'assets/img/products/bol/BOL 16.png',
    'assets/img/products/bol/BOL 14.png',
    'assets/img/products/bol/BOL 15.png',
    'assets/img/products/bol/BOL 11.png']

export const snuffleMatImages: string[] = ['assets/img/products/mat/MAT Rainbow - Stripe.png', 'assets/img/products/mat/MAT Surprise Me - Circle.png', 'assets/img/products/mat/Snuffle Mat Block.png', 'assets/img/products/mat/Snuffle Mat Stripe Pastel.png']

export const snuffleMat2Images: string[] = ['assets/img/products/mat2.0/Mat 2.0 Stripe Combined.png', 'assets/img/products/mat2.0/Mat 2.0 Stripe.png', 'assets/img/products/mat/Mat 2.0 X Combined.png', 'assets/img/products/mat2.0/Mat 2.0 X1.png', 'assets/img/products/mat2.0/Mat 2.0 X2.png']

export const tugStickImages: string[] = ['TUG STIK 2.png', 'TUG STIK REG.png', 'TUG STIK W_ KNOT.png']

export const xStickImages: string[] = ['X STIK.png', 'X STIK 1.png']

export const doubleHaloImages: string[] = ['INFINITY RINGS.png', 'INFINITY RINGS 2.png']

export const haloImages: string[] = ['OCTO REG.png', 'OCTO SMOL.png', 'OCTO-STIK REG.png']

export const pomImages: string[] = ['assets/img/products/pom/POM 2.png', 'assets/img/products/pom/POM 3.png', 'assets/img/products/pom/POM 4.png', 'assets/img/products/pom/POM 5.png', 'assets/img/products/pom/POM 6.png']

export const daddyLongLegImages: string[] = ['DADDY LONG LEGS MEGA.png', 'DADDY LONG LEGS.png']

export const tugAPillarImages: string[] = ['TUGAPILLAR.png', 'TUGAPILLAR MEGA.png', 'TUGAPILLAR CARROT.png']

export const serpentImages: string[] = ['assets/img/products/serpent/serpent_1.PNG', 'assets/img/products/serpent/serpent_9.PNG', 
      'assets/img/products/serpent/serpent_10.PNG', 'assets/img/products/serpent/serpent_11.PNG', 'assets/img/products/serpent/serpent_12.PNG']