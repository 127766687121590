import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import { RootState } from '../store';
import { fetchProductByType, fetchProductByName, fetchProductByItem } from '../api/ProductService';
import { Product } from "../common/types"

interface ProductState {
    data: Product[];
    loadingByType: boolean;
    loadingByItem: boolean;
    error: string | null;
    colors: string[];
    secondaryColors: string[];
    hex: string[];
    productType: string[];
    size: string[];
    level: string[];
    length: number[];
    basePrice: number;
    additionalPrice: number;
    updateColorList: boolean;
    currProductType: string;
}

const initialState: ProductState = {
    data: [],
    loadingByType: true,
    loadingByItem: true,
    currProductType: "",
    error: null,
    colors: [],
    secondaryColors: [],
    hex: [],
    productType: [],
    size: [],
    level: [],
    length: [],
    basePrice: 0,
    additionalPrice: 0,
    updateColorList: false
}

export const fetchProductByTypeAsync = createAsyncThunk(
    'product/fetchByType',
    async (product: { productType: string }) => {
        const response = await fetchProductByType(product.productType)
        return response
    }
);

export const fetchProductByItemAsync = createAsyncThunk(
    'product/fetchByItem',
    async (product: { productItem: string }) => {
        const response = await fetchProductByItem(product.productItem)
        return response
    }
);

export const fetchProductByNameAsync = createAsyncThunk(
    'product/fetchByName',
    async (product: { productName: string}) => {
        const response = await fetchProductByName(product.productName)
        return response
    }
)

const clearAction = createAction('clear')

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setColors(state, action: PayloadAction<string[]>) {
            state.colors = action.payload
        },
        setCurrProductType(state, action: PayloadAction<string>) {
            state.currProductType = action.payload
        },
        setHex(state, action: PayloadAction<string[]>) {
            state.hex = action.payload
        },
        setAdditionalPrice(state, action: PayloadAction<number>) {
            state.additionalPrice = action.payload
        },
        setUpdateFlag(state, action: PayloadAction<boolean>) {
            state.updateColorList =  action.payload
        },
        setSecondaryColors(state, action: PayloadAction<string[]>) {
            state.secondaryColors = action.payload
        },
        setBasePrice(state, action: PayloadAction<number>) {
            state.basePrice = action.payload
        },
        resetColors(state) {
            state.colors = []
            state.hex = []
            state.secondaryColors = []
        },
        resetState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductByItemAsync.pending, (state) => {
                    
                state.loadingByItem = true;
                state.error = null
            })
            .addCase(fetchProductByItemAsync.fulfilled, (state, action) => {
                ;
                state.loadingByItem = false;
                state.data = action.payload;
                action.payload.forEach((product: Product) => {
                    console.log("products from slice...", product)
                    console.log("length...", product.length)
                    if (!state.productType.includes(product.name)) {
                        state.productType.push(product.name);
                    }
                    if (!state.size.includes(product.size) && product.size != null) {
                        state.size.push(product.size);
                    }
                    if (!state.level.includes(product.level) && product.level != null) {
                        state.level.push(product.level);
                    }
                    if (!state.length.includes(product.length) && product.length != null) {
                        state.length.push(product.length);
                    }
                });
            })
            .addCase(fetchProductByItemAsync.rejected, (state, action) => {
                state.loadingByItem = true;
                state.error = action.error.message || 'Failed to fetch invoice';
            })
            .addCase(fetchProductByTypeAsync.pending, (state) => {
                
                state.loadingByType = true;
                state.error = null
            })
            .addCase(fetchProductByTypeAsync.fulfilled, (state, action) => {
                ;
                state.loadingByType = false;
                state.data = action.payload;
                action.payload.forEach((product: Product) => {
                    
                    if (!state.productType.includes(product.name)) {
                        state.productType.push(product.name);
                    }
                    if (!state.size.includes(product.size) && product.size != null) {
                        state.size.push(product.size);
                    }
                    if (!state.level.includes(product.level) && product.level != null) {
                        state.level.push(product.level);
                    }
                });
            })
            .addCase(fetchProductByTypeAsync.rejected, (state, action) => {
                state.loadingByType = true;
                state.error = action.error.message || 'Failed to fetch invoice';
            })
            .addCase(fetchProductByNameAsync.pending, (state) => {
                
                state.loadingByType = true;
                state.error = null
            })
            .addCase(fetchProductByNameAsync.fulfilled, (state, action) => {
                ;
                state.loadingByType = false;
                state.data = action.payload;
                action.payload.forEach((product: Product) => {
                    
                    if (!state.productType.includes(product.name)) {
                        state.productType.push(product.name);
                    }
                    if (!state.size.includes(product.size) && product.size != null) {
                        state.size.push(product.size);
                    }
                    if (!state.level.includes(product.level) && product.level != null) {
                        state.level.push(product.level);
                    }
                });
            })
            .addCase(fetchProductByNameAsync.rejected, (state, action) => {
                state.loadingByType = true;
                state.error = action.error.message || 'Failed to fetch invoice';
            })
            .addCase(clearAction, () => initialState)
    }
})

export const { setColors, resetColors, setHex, setSecondaryColors, setBasePrice, setAdditionalPrice, setUpdateFlag, setCurrProductType, resetState } = productSlice.actions;

export default productSlice.reducer;

export const productData = (state: RootState) => state.products.data
export const currProductType = (state: RootState) => state.products.currProductType
export const selectProductTypeLoading = (state: RootState) => state.products.loadingByType
export const selectProductItemLoading = (state: RootState) => state.products.loadingByItem
export const hexState = (state: RootState) => state.products.hex
export const colorState = (state: RootState) => state.products.colors
export const secondaryColorState = (state: RootState) => state.products.secondaryColors
export const basePrice = (state: RootState) => state.products.basePrice
export const additionalPrice = (state: RootState) => state.products.additionalPrice
export const updateColorList = (state: RootState) => state.products.updateColorList