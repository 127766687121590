import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {Card,CardBody} from "@material-tailwind/react";
import "../styles/HomePage.scss";

const CONTENTS = [
    {
      img: "/assets/img/products/bol/BOL 2.png",
      name: "Snuffle",
      price: "35.00",
      uri: "/snuffle"
    },
    {
        img: "/OCTO.png",
        name: "Play",
        price: "17.00",
        uri: "/play"
    },
  ];

interface ProductListCardPropsType {
    img: string;
    name: string;
    price: string;
    uri: string;
}
  
export function ProductListCard({img,name,price,uri}: ProductListCardPropsType) {
    let navigate = useNavigate();
    const handleListClick = (toWhere: string) => {
        navigate(toWhere);
    }

    return (
        <div className="productInfo" onClick={() => handleListClick(uri)}>
            <div className="flex justify-center rounded-2xl border-transparent bg-white p-5 shadow-lg shadow-gray-50/20 itemImage">
                <img src={img} alt={name} className="max-h-[150px] md:max-h-[200px]" />
            </div>
            <div className="text-center mt-2 itemInfo">
                <div><h5 className="font-bold md:mt-5">{name}</h5></div>
                <div className="text-gray-500 italic">from ${price}</div>
            </div>
        </div>
    );
  }

const HomePage = () => {
    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    let resizeWindow = () => {
        setMobile(window.innerWidth <= 768);
    };
  
    useEffect(() => {
      resizeWindow();
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }, []);


    return (
        <div className={`home-container ${isMobile? "mobile" : ""}`}>
            <div className="header-banner flex flex-col justify-center">
                
                <div className="container mx-auto grid grid-cols-5 p-5 md:p-0 mx-auto">
                <div className="col-span-3 lg:col-span-2">
                    <h1 className="noto-serif text-lg md:text-4xl">Spreading the joy of <strong>being curious</strong></h1>
                    <div className="text-sm md:text-xl mt-3 pb-10 md:pb-0">
                    Meticulously handcrafted custom enrichment products for pets
                    </div>
                    </div>
                </div>

            </div>

            <section className="productsView">
                <div className="mx-auto container">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 md:grid-cols-2">
                    {CONTENTS.map(({ img, name, price, uri}, index) => (
                        <ProductListCard key={index} img={img} name={name} price={price} uri={uri}
                        />
                    ))}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomePage;
