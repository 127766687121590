import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { fetchProductByTypeAsync, currProductType, productData, resetState } from "../features/productSlice";
import { productItemImageMap } from '../common/imageMap';
import "../styles/ProductTypeSelection.scss";

interface ProductTypeSelectionPageProps {
    productType: string;
}

interface Product {
    item: string;
    price: number;
    image?: string;
    type: string;
}

export function ProductListCard({ image, item, price, type }: Product) {
    const navigate = useNavigate();

    const handleButtonClick = (productName: string) => {
        navigate(`/${type}/customise/${productName}`);
    };
    console.log("this is item from pdocut ist card...", item)
    return (
        <div className="productInfo" onClick={() => handleButtonClick(item)}>
            <div className="flex justify-center rounded-2xl border-transparent bg-white p-5 shadow-lg shadow-gray-50/20 itemImage">
                <img src={image} alt={item} className="max-h-[150px] md:max-h-[200px]" />
            </div>
            <div className="text-center mt-2 itemInfo">
                <h5 className="font-bold md:mt-5">{item}</h5>
                {item == 'Snuffle Mat' ? <><div className="text-red-500 line-through italic">from $48.00</div> <div className="text-gray-500 italic">from ${price.toFixed(2)}</div></> : <div className="text-gray-500 italic">from ${price.toFixed(2)}</div>}
            </div>
        </div>
    );
}

const ProductTypeSelectionPage: React.FC<ProductTypeSelectionPageProps> = ({ productType }) => {
    const dispatch = useDispatch<AppDispatch>();
    const currentProductType = useSelector(currProductType);
    const productLoaded = useSelector(productData);
    const renderedProductItems: { [key: string]: boolean } = {};

    // Preprocess product data to find the cheapest price for each product
    const productWithCheapestPrice = productLoaded.reduce<{ [key: string]: Product }>((acc, product) => {
        const { item, price } = product;
    
        // If the product is already in the accumulator, compare and keep the lowest price
        if (!acc[item] || price < acc[item].price) {
            acc[item] = product;
        }
    
        return acc;
    }, {});  // Initial value is an empty object
    
    const normalizeKey = (key: string): string => {
        if(key === "Snuffle Mat 2.0") {
            return key.toLowerCase().replace(/[\s-.]/g, '_');
        } else {
            return key.toLowerCase().replace(/[\s-]/g, '')
        }
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                await dispatch(fetchProductByTypeAsync({ productType }));
            } catch (error) {
                console.error("Error fetching product: ", error);
            }
        };

        if (productType !== currentProductType) {
            dispatch(resetState());
            fetchProduct();
        }
    }, [productType, dispatch, currentProductType]);

    return (
        <div className="mx-auto container">
            <h3 className="block font-sans text-3xl antialiased font-semibold leading-snug tracking-normal text-inherit text-center my-6">
                {productType}
            </h3>
            <div className="m-3 grid grid-cols-1 gap-8 lg:grid-cols-2 md:grid-cols-2 md:mx-0">
                {Object.values(productWithCheapestPrice).length > 0 ? (
                    Object.values(productWithCheapestPrice).map(product => {
                        const { item, price } = product;
                        if (!renderedProductItems[item]) {
                            renderedProductItems[item] = true; // Mark as rendered
                            return (
                                <ProductListCard
                                    key={item}
                                    image={productItemImageMap[normalizeKey(item)]}
                                    item={item}
                                    price={price}
                                    type={productType}
                                />
                            );
                        }
                        return null;
                    })
                ) : (
                    "Loading"
                )}
            </div>
        </div>
    );
};

export default ProductTypeSelectionPage;
